<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-col cols="12">
      <v-card-title
        class="noPrint"
        style="background-color: transparent !important"
      >
        <h3>گزارش عملکرد</h3>
      </v-card-title>
      <v-tabs
        class="noPrint"
        background-color="transparent"
        v-model="tab"
        fixed-tabs
      >
        <v-tab class="tab-title"> به تفکیک پزشک </v-tab>
        <v-tab class="tab-title"> به تفکیک روز </v-tab>
      </v-tabs>
      <v-card class="br-card">
        <v-card class="pa-3" flat>
          <v-tabs-items v-model="tab">
            <!-- به تفکیک پزشک -->
            <v-tab-item>
              <v-card class="pa-2">
                <v-row class="time-row">
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <span id="dateFrom2">
                      <v-text-field
                        outlined
                        dense
                        type="text"
                        append-icon="calendar_today"
                        v-model="dateFrom"
                        label=" تاریخ از "
                        :editable="true"
                        class="date-input"
                      >
                      </v-text-field>
                    </span>

                    <date-picker
                      v-model="dateFrom"
                      element="dateFrom2"
                      color="#00a7b7"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <span id="dateTo2">
                      <v-text-field
                        outlined
                        dense
                        type="text"
                        append-icon="calendar_today"
                        v-model="dateTo"
                        label=" تاریخ تا "
                        :editable="true"
                        class="date-input"
                      >
                      </v-text-field>
                    </span>

                    <date-picker
                      v-model="dateTo"
                      element="dateTo2"
                      color="#00a7b7"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn
                      class="submit-btn primary-btn noPrint"
                      style="float: right"
                      @click="doclyReport()"
                      >اعمال</v-btn
                    >
                  </v-col>
                  <v-col v-if="doclyReady">
                    <div>
                      <v-btn
                        class="secondary-btn float-end"
                        @click="pdf('doclyTab')"
                        >پرینت گزارش</v-btn
                      >
                    </div></v-col
                  >
                </v-row>
                <div v-if="doclyReady">
                  <hr />
                  <v-row align-v="center" class="mt-4 mb-2">
                    <v-col><h4 class="primary--text">پزشکان عمومی</h4></v-col>
                    <v-col>
                      <v-btn
                        style="float: left"
                        v-if="doclyReady && generalDocs != []"
                        class="secondary-btn"
                        @click="$refs.generaldocsExcel.$el.click()"
                        :disabled="!generalDocs.length"
                        >دریافت اکسل پزشکان عمومی</v-btn
                      >
                      <vue-excel-xlsx
                        v-show="false"
                        ref="generaldocsExcel"
                        :data="generalDocs"
                        :columns="
                          excelGeneralDocFields.map((x) => {
                            return {
                              label: x.label,
                              field: x.key,
                            };
                          })
                        "
                        :filename="'گزارش به تفکیک دکتر(عمومی)'"
                        :sheetname="currentDate"
                      >
                        دریافت اکسل
                      </vue-excel-xlsx>
                    </v-col>
                  </v-row>

                  <b-table
                    responsive
                    small
                    show-empty
                    :fields="doclyGeneralFields"
                    :items="generalDocs"
                    empty-text="در بازه زمانی انتخاب شده پزشکی برای نمایش وجود ندارد"
                    empty-filtered-text="در بازه زمانی انتخاب شده پزشکی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(totalBaliniIncome)="data">
                      <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            style="
                              text-align: center;
                              vertical-align: middle;
                              cursor: pointer;
                            "
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{
                              typeof data.value === "number"
                                ? Number(data.value).toLocaleString()
                                : data.value
                            }}
                          </div>
                        </template>
                        <span>تعداد خدمات: {{ data.item.baliniCount }}</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:cell(totalSurgeryIncome)="data">
                      <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            style="
                              text-align: center;
                              vertical-align: middle;
                              cursor: pointer;
                            "
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{
                              typeof data.value === "number"
                                ? Number(data.value).toLocaleString()
                                : data.value
                            }}
                          </div>
                        </template>
                        <span>تعداد خدمات: {{ data.item.surgeryCount }}</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                    <div
                      slot="table-busy"
                      class="text-center primary--text my-2"
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        class="align-middle"
                      ></v-progress-circular>
                    </div>
                  </b-table>
                  <hr />
                  <v-row align-v="center" class="mt-4 mb-2">
                    <v-col><h4 class="primary--text">پزشکان متخصص</h4></v-col>
                    <v-col>
                      <v-btn
                        style="float: left"
                        v-if="doclyReady && expertDocs != []"
                        class="secondary-btn"
                        @click="$refs.expertDocsExcel.$el.click()"
                        :disabled="!expertDocs.length"
                        >دریافت اکسل پزشکان متخصص</v-btn
                      >

                      <vue-excel-xlsx
                        v-show="false"
                        ref="expertDocsExcel"
                        :data="expertDocs"
                        :columns="
                          excelDocFields.map((x) => {
                            return {
                              label: x.label,
                              field: x.key,
                            };
                          })
                        "
                        :filename="'گزارش به تفکیک دکتر(متخصص)'"
                        :sheetname="currentDate"
                      >
                        دریافت اکسل
                      </vue-excel-xlsx>
                    </v-col>
                  </v-row>

                  <b-table
                    responsive
                    small
                    show-empty
                    :fields="doclyNonGeneralFields"
                    :items="expertDocs"
                    empty-text="در بازه زمانی انتخاب شده پزشکی برای نمایش وجود ندارد"
                    empty-filtered-text="در بازه زمانی انتخاب شده پزشکی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(totalExpertClinicalIncome)="data">
                      <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            style="
                              text-align: center;
                              vertical-align: middle;
                              cursor: pointer;
                            "
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{
                              typeof data.value === "number"
                                ? Number(data.value).toLocaleString()
                                : data.value
                            }}
                          </div>
                        </template>
                        <span
                          >تعداد خدمات:
                          {{ data.item.expertClinicalCount }}</span
                        >
                      </v-tooltip>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                    <div
                      slot="table-busy"
                      class="text-center primary--text my-2"
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        class="align-middle"
                      ></v-progress-circular>
                    </div>
                  </b-table>
                  <hr />
                  <v-row align-v="center" class="mt-4 mb-2">
                    <v-col><h4 class="primary--text">دندانپزشکان</h4></v-col>
                    <v-col>
                      <v-btn
                        style="float: left"
                        v-if="doclyReady && dentists != []"
                        class="secondary-btn"
                        :disabled="!dentists.length"
                        @click="$refs.dentistsExcel.$el.click()"
                        >دریافت اکسل دندانپزشکان</v-btn
                      >

                      <vue-excel-xlsx
                        v-show="false"
                        ref="dentistsExcel"
                        :data="dentists"
                        :columns="
                          dentistsFields.map((x) => {
                            return {
                              label: x.label,
                              field: x.key,
                            };
                          })
                        "
                        :filename="'گزارش به تفکیک دکتر(دندانپزشک)'"
                        :sheetname="currentDate"
                      >
                        دریافت اکسل
                      </vue-excel-xlsx>
                    </v-col>
                  </v-row>

                  <b-table
                    responsive
                    small
                    show-empty
                    :fields="dentistsFields"
                    :items="dentists"
                    empty-text="در بازه زمانی انتخاب شده پزشکی برای نمایش وجود ندارد"
                    empty-filtered-text="در بازه زمانی انتخاب شده پزشکی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                    <div
                      slot="table-busy"
                      class="text-center primary--text my-2"
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        class="align-middle"
                      ></v-progress-circular>
                    </div>
                  </b-table>

                  <div id="doclyTab" v-show="false">
                    <v-row align-v="center" class="mt-4 mb-2">
                      <v-col
                        ><h4 class="primary--text text-center">
                          پزشکان عمومی
                        </h4></v-col
                      >
                    </v-row>

                    <b-table
                      responsive
                      small
                      show-empty
                      :fields="doclyGeneralFields"
                      :items="generalDocs"
                      empty-text="در بازه زمانی انتخاب شده پزشکی برای نمایش وجود ندارد"
                      empty-filtered-text="در بازه زمانی انتخاب شده پزشکی برای نمایش وجود ندارد"
                    >
                      <template v-slot:head()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.label }}
                        </div>
                      </template>
                      <template v-slot:cell()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{
                            typeof data.value === "number"
                              ? Number(data.value).toLocaleString()
                              : data.value
                          }}
                        </div>
                      </template>
                      <template v-slot:cell(index)="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.index + 1 }}
                        </div>
                      </template>
                      <div
                        slot="table-busy"
                        class="text-center primary--text my-2"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                          class="align-middle"
                        ></v-progress-circular>
                      </div>
                    </b-table>
                    <hr />
                    <v-row align-v="center" class="mt-4 mb-2">
                      <v-col
                        ><h4 class="primary--text text-center">
                          پزشکان متخصص
                        </h4></v-col
                      >
                    </v-row>

                    <b-table
                      responsive
                      small
                      show-empty
                      :fields="doclyNonGeneralFields"
                      :items="expertDocs"
                      empty-text="در بازه زمانی انتخاب شده پزشکی برای نمایش وجود ندارد"
                      empty-filtered-text="در بازه زمانی انتخاب شده پزشکی برای نمایش وجود ندارد"
                    >
                      <template v-slot:head()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.label }}
                        </div>
                      </template>
                      <template v-slot:cell()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{
                            typeof data.value === "number"
                              ? Number(data.value).toLocaleString()
                              : data.value
                          }}
                        </div>
                      </template>
                      <template v-slot:cell(index)="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.index + 1 }}
                        </div>
                      </template>
                      <div
                        slot="table-busy"
                        class="text-center primary--text my-2"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                          class="align-middle"
                        ></v-progress-circular>
                      </div>
                    </b-table>
                    <hr />
                    <v-row align-v="center" class="mt-4 mb-2">
                      <v-col
                        ><h4 class="primary--text text-center">
                          دندانپزشکان
                        </h4></v-col
                      >
                    </v-row>

                    <b-table
                      responsive
                      small
                      show-empty
                      :fields="dentistsFields"
                      :items="dentists"
                      empty-text="در بازه زمانی انتخاب شده پزشکی برای نمایش وجود ندارد"
                      empty-filtered-text="در بازه زمانی انتخاب شده پزشکی برای نمایش وجود ندارد"
                    >
                      <template v-slot:head()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.label }}
                        </div>
                      </template>
                      <template v-slot:cell()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{
                            typeof data.value === "number"
                              ? Number(data.value).toLocaleString()
                              : data.value
                          }}
                        </div>
                      </template>
                      <template v-slot:cell(index)="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.index + 1 }}
                        </div>
                      </template>
                      <div
                        slot="table-busy"
                        class="text-center primary--text my-2"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                          class="align-middle"
                        ></v-progress-circular>
                      </div>
                    </b-table>
                  </div>
                </div>
              </v-card>
            </v-tab-item>
            <!-- به تفکیک روز -->
            <v-tab-item>
              <v-card class="pa-2">
                <v-row class="noPrint time-row">
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <span id="dateFrom3">
                      <v-text-field
                        outlined
                        dense
                        type="text"
                        append-icon="calendar_today"
                        v-model="dateFrom"
                        label=" تاریخ از "
                        :editable="true"
                        class="date-input"
                      >
                      </v-text-field>
                    </span>

                    <date-picker
                      v-model="dateFrom"
                      element="dateFrom3"
                      color="#00a7b7"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <span id="dateTo3">
                      <v-text-field
                        outlined
                        dense
                        type="text"
                        append-icon="calendar_today"
                        v-model="dateTo"
                        label=" تاریخ تا "
                        :editable="true"
                        class="date-input"
                      >
                      </v-text-field>
                    </span>

                    <date-picker
                      v-model="dateTo"
                      element="dateTo3"
                      color="#00a7b7"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn
                      class="submit-btn primary-btn noPrint"
                      style="float: right"
                      @click="dayReport()"
                      >اعمال</v-btn
                    >
                  </v-col>
                </v-row>
                <div v-if="dailyReady" class="pa-2">
                  <hr />
                  <v-tabs
                    background-color="transparent"
                    vertical
                    v-model="innerTab"
                    :style="
                      deviceType == 'mobile'
                        ? 'border-bottom : 1px solid rgba(0, 0, 0, 0.1) !important'
                        : ''
                    "
                  >
                    <v-tab
                      class="tab-title noPrint"
                      style="border-radius: 0 !important"
                      >درمانگاه عمومی</v-tab
                    >
                    <v-tab
                      class="tab-title noPrint"
                      style="border-radius: 0 !important"
                      >درمانگاه‌های تخصصی</v-tab
                    >
                    <v-tab
                      class="tab-title noPrint"
                      style="border-radius: 0 !important"
                      >خدمات در منزل</v-tab
                    >
                    <v-tab
                      class="tab-title noPrint"
                      style="border-radius: 0 !important"
                      >خدمات دندانپزشکی
                    </v-tab>
                    <v-tab
                      class="tab-title noPrint"
                      style="border-radius: 0 !important"
                      >پاراکلینیک‌ها</v-tab
                    >
                    <v-tab
                      class="tab-title noPrint"
                      style="border-radius: 0 !important"
                      >اقلام مصرفی</v-tab
                    >
                    <v-tab
                      class="tab-title noPrint"
                      style="border-radius: 0 !important"
                      >مجموع عملکردها</v-tab
                    >

                    <v-tabs-items v-model="innerTab" id="Report">
                      <!-- درمانگاه عمومی-->
                      <v-tab-item>
                        <v-card class="pa-4">
                          <v-row class="my-3 noPrint">
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                              <v-btn
                                class="text-right secondary-btn mt-1"
                                @click="print()"
                              >
                                پرینت گزارش درمانگاه عمومی
                              </v-btn>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                              <v-btn
                                class="secondary-btn mt-1"
                                style="float: left"
                                @click="$refs.generalExcel.$el.click()"
                              >
                                دریافت اکسل درمانگاه عمومی
                              </v-btn>
                              <vue-excel-xlsx
                                v-show="false"
                                ref="generalExcel"
                                :data="generalExcel"
                                :columns="
                                  generalExcelShiftsFields.map((x) => {
                                    return {
                                      label: x.label,
                                      field: x.key,
                                    };
                                  })
                                "
                                :filename="'خروجی به ازای شیفت(عمومی)'"
                                :sheetname="currentDate"
                              >
                                دریافت اکسل
                              </vue-excel-xlsx></v-col
                            >
                          </v-row>
                          <div id="general">
                            <h5 class="print-h5">درمانگاه عمومی</h5>
                            <Tabs
                              :fields="generalShiftsFields"
                              :opens="opens"
                              :total="daysGeneralTotal"
                              :item="excelDailyReport"
                            />
                          </div>
                        </v-card>
                      </v-tab-item>
                      <!-- درمانگاه‌های تخصصی -->
                      <v-tab-item>
                        <v-card class="pa-4">
                          <v-row class="my-3 noPrint">
                            <v-col>
                              <v-btn
                                class="text-right secondary-btn mt-1"
                                @click="print()"
                              >
                                پرینت گزارش درمانگاه‌های تخصصی
                              </v-btn>
                              <v-btn
                                class="secondary-btn mt-1"
                                style="float: left"
                                @click="$refs.expertiseExcel.$el.click()"
                              >
                                دریافت اکسل درمانگاه‌های تخصصی</v-btn
                              >
                              <vue-excel-xlsx
                                v-show="false"
                                ref="expertiseExcel"
                                :data="expertiseExcel"
                                :columns="
                                  expertiseExcelShiftsFields.map((x) => {
                                    return {
                                      label: x.label,
                                      field: x.key,
                                    };
                                  })
                                "
                                :filename="'خروجی به ازای شیفت(تخصصی)'"
                                :sheetname="currentDate"
                              >
                                دریافت اکسل
                              </vue-excel-xlsx>
                            </v-col>
                          </v-row>
                          <div id="experties">
                            <h5 class="print-h5">درمانگاه‌های تخصصی</h5>
                            <Tabs
                              :fields="expertiseShiftsFields"
                              :opens="opens"
                              :total="daysExpertiseTotal"
                              :item="excelDailyReport"
                            />
                          </div>
                        </v-card>
                      </v-tab-item>
                      <!-- خدمات در منزل -->
                      <v-tab-item>
                        <v-card class="pa-4">
                          <v-row class="my-3 noPrint">
                            <v-col>
                              <v-btn
                                class="text-right secondary-btn mt-1"
                                @click="print()"
                              >
                                پرینت گزارش خدمات در منزل
                              </v-btn>
                              <v-btn
                                class="secondary-btn mt-1"
                                style="float: left"
                                @click="$refs.outServicesExcel.$el.click()"
                                >دریافت اکسل خدمات در منزل</v-btn
                              >
                              <vue-excel-xlsx
                                v-show="false"
                                ref="outServicesExcel"
                                :data="homecareExcel"
                                :columns="
                                  outserviceExcelFields.map((x) => {
                                    return {
                                      label: x.label,
                                      field: x.key,
                                    };
                                  })
                                "
                                :filename="'خروجی به ازای روز(در منزل)'"
                                :sheetname="currentDate"
                              >
                                دریافت اکسل
                              </vue-excel-xlsx>
                            </v-col>
                          </v-row>
                          <div id="outServices">
                            <h5 class="print-h5">خدمات در منزل</h5>
                            <Tabs
                              :fields="outserviceShiftsFields"
                              :opens="opens"
                              :total="daysOutServiceTotal"
                              :item="excelDailyReport"
                            />
                          </div>
                        </v-card>
                      </v-tab-item>
                      <!-- دندانپزشکی -->
                      <v-tab-item>
                        <v-card class="pa-4">
                          <v-row class="my-3 noPrint">
                            <v-col>
                              <v-btn
                                class="text-right secondary-btn mt-1"
                                @click="print()"
                              >
                                پرینت گزارش خدمات دندانپزشکی
                              </v-btn>
                              <v-btn
                                class="secondary-btn mt-1"
                                style="float: left"
                                @click="$refs.dentistServicesExcel.$el.click()"
                                >دریافت اکسل خدمات دندانپزشکی</v-btn
                              >
                              <vue-excel-xlsx
                                v-show="false"
                                ref="dentistServicesExcel"
                                :data="dentistaryExcel"
                                :columns="
                                  dentistExcelFields.map((x) => {
                                    return {
                                      label: x.label,
                                      field: x.key,
                                    };
                                  })
                                "
                                :filename="'خروجی به ازای روز(دندانپزشکی)'"
                                :sheetname="currentDate"
                              >
                                دریافت اکسل
                              </vue-excel-xlsx>
                            </v-col>
                          </v-row>
                          <div id="dentistry">
                            <h5 class="print-h5">خدمات دندانپزشکی</h5>
                            <Tabs
                              :fields="dentistShiftsFields"
                              :opens="opens"
                              :total="daysdentistTotal"
                              :item="excelDailyDentristryReport"
                            />
                          </div>
                        </v-card>
                      </v-tab-item>
                      <!-- پاراکلینیک‌ها -->
                      <v-tab-item>
                        <v-card class="pa-4">
                          <v-row class="my-3 noPrint">
                            <v-col>
                              <v-btn
                                class="text-right secondary-btn mt-1"
                                @click="print()"
                              >
                                پرینت گزارش پاراکلینیک‌ها
                              </v-btn>
                              <v-btn
                                class="secondary-btn mt-1"
                                style="float: left"
                                @click="$refs.labServicesExcel.$el.click()"
                                >دریافت اکسل پاراکلینیک‌ها</v-btn
                              >
                              <vue-excel-xlsx
                                v-show="false"
                                ref="labServicesExcel"
                                :data="labExcel"
                                :columns="labExcelFields"
                                :filename="'خروجی به ازای روز(آزمایشگاه)'"
                                :sheetname="currentDate"
                              >
                                دریافت اکسل
                              </vue-excel-xlsx>
                            </v-col>
                          </v-row>
                          <div id="lab">
                            <h5 class="print-h5">خدمات آزمایشگاه</h5>
                            <Tabs
                              :fields="labShiftsFields"
                              :opens="opens"
                              :total="daysLabTotal"
                              :item="excelDailyReport"
                            />
                          </div> </v-card
                      ></v-tab-item>
                      <!-- اقلام مصرفی -->
                      <v-tab-item>
                        <v-card class="pa-4">
                          <v-row class="my-3 noPrint">
                            <v-col>
                              <v-btn
                                class="text-right secondary-btn mt-1"
                                @click="print()"
                              >
                                پرینت گزارش اقلام مصرفی
                              </v-btn>
                              <v-btn
                                class="secondary-btn mt-1"
                                style="float: left"
                                @click="$refs.commodityExcel.$el.click()"
                                >دریافت اکسل اقلام مصرفی</v-btn
                              >
                              <vue-excel-xlsx
                                v-show="false"
                                ref="commodityExcel"
                                :data="commodityExcel"
                                :columns="commodityExcelFields"
                                :filename="'خروجی به ازای روز(اقلام مصرفی)'"
                                :sheetname="currentDate"
                              >
                                دریافت اکسل
                              </vue-excel-xlsx>
                            </v-col>
                          </v-row>

                          <div id="lab">
                            <h5 class="print-h5">اقلام مصرفی</h5>
                            <Tabs
                              :fields="commodityShiftsFields"
                              :opens="opens"
                              :total="daysCommodityTotal"
                              :item="excelDailyReport"
                            />
                          </div>
                          <v-row class="my-3 noPrint">
                            <v-col>
                              <v-row class="mb-3 d-flex align-items-center">
                                <v-col>
                                  <h5 class="print-h5">
                                    لیست کلیه اقلام مصرفی
                                  </h5>
                                </v-col>
                                <v-col class="text-end">
                                  <vue-excel-xlsx
                                    :data="commodityList"
                                    :columns="
                                      commodityListFields.map((x) => {
                                        return {
                                          label: x.label,
                                          field: x.key,
                                        };
                                      })
                                    "
                                    :filename="'گزارش اقلام مصرفی'"
                                    :sheetname="currentDate"
                                    class="primary-btn v-btn ps-3 pe-3 py-2"
                                  >
                                    دریافت اکسل
                                  </vue-excel-xlsx></v-col
                                >
                              </v-row>

                              <b-table
                                responsive
                                small
                                show-empty
                                :items="commodityList"
                                :fields="commodityListFields"
                                empty-text="در بازه زمانی انتخاب شده اقلامی برای نمایش وجود ندارد"
                                empty-filtered-text="در بازه زمانی انتخاب شده اقلامی برای نمایش وجود ندارد"
                              >
                                <template v-slot:head()="data">
                                  <div
                                    style="
                                      text-align: center;
                                      vertical-align: middle;
                                    "
                                  >
                                    {{ data.label }}
                                  </div>
                                </template>
                                <template v-slot:cell()="data">
                                  <div
                                    style="
                                      text-align: center;
                                      vertical-align: middle;
                                    "
                                  >
                                    {{
                                      typeof data.value === "number"
                                        ? Number(data.value).toLocaleString()
                                        : data.value
                                    }}
                                  </div>
                                </template>

                                <template v-slot:cell(index)="data">
                                  <div
                                    style="
                                      text-align: center;
                                      vertical-align: middle;
                                    "
                                  >
                                    {{ data.index + 1 }}
                                  </div>
                                </template>
                                <div
                                  slot="table-busy"
                                  class="text-center primary--text my-2"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="primary"
                                    class="align-middle"
                                  ></v-progress-circular>
                                </div>
                              </b-table>
                            </v-col>
                          </v-row> </v-card
                      ></v-tab-item>
                      <!-- مجموع عملکردها -->
                      <v-tab-item>
                        <v-card class="pa-4">
                          <v-row class="my-3 noPrint">
                            <v-col>
                              <v-btn
                                class="text-right secondary-btn mt-1"
                                @click="print()"
                              >
                                پرینت گزارش کلیه عملکردها
                              </v-btn>
                              <v-btn
                                class="secondary-btn mt-1"
                                style="float: left"
                                @click="$refs.sumExcel.$el.click()"
                                >دریافت اکسل کلیه عملکردها</v-btn
                              >
                              <vue-excel-xlsx
                                v-show="false"
                                ref="sumExcel"
                                :data="sumExcel"
                                :columns="sumExcelFields"
                                :filename="'خروجی به ازای شیفت(مجموع عملکردها)'"
                                :sheetname="currentDate"
                              >
                                دریافت اکسل
                              </vue-excel-xlsx>
                            </v-col>
                          </v-row>
                          <div id="all">
                            <h5 class="print-h5">مجموع عملکردها</h5>
                            <Tabs
                              :fields="sumFields"
                              :opens="opens"
                              :total="sum"
                              :item="excelDailyReport"
                            />
                          </div> </v-card
                      ></v-tab-item>
                    </v-tabs-items>
                  </v-tabs>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
const Tabs = () => import("../../components/report/proccessReport/Tabs.vue");

export default {
  components: {
    datePicker: VuePersianDatetimePicker,
    Tabs,
  },
  data() {
    return {
      deviceTye: "",
      tab: null,
      innerTab: null,
      opens: [],
      Busy: false,
      moment: moment,
      weekDict: [
        "شنبه",
        "یکشنبه",
        "دوشنبه",
        "سه شنبه",
        "چهارشنبه",
        "پنج شنبه",
        "جمعه",
      ],
      TotalRows: "",
      totalDocCost: "",
      totalIncome: "",
      totalExpen: "",
      shiftDoc: [],
      CurrentPage: 1,
      PerPage: 10,
      shiftNurse: [],
      shiftReception: [],
      // daily fields
      generalShiftsFields: [
        { key: "shiftName", label: "نوبت شیفت" },
        { key: "visitTotalCount", label: "تعداد ویزیت" },
        { key: "visitTotalTotal", label: "کارکرد ویزیت" },
        { key: "baliniTotalCount", label: "تعداد خدمات پرستاری" },
        { key: "baliniTotalTotal", label: "کارکرد خدمات پرستاری" },
        { key: "surgeryTotalCount", label: "تعداد خدمات پزشک" },
        { key: "surgeryTotalTotal", label: "کارکرد خدمات پزشک" },
        // { key: "homeCareTotalCount", label: "تعداد خدمات در منزل" },
        // { key: "homeCareTotalCost", label: "کارکرد خدمات در منزل" },
        { key: "generalCosts", label: "کارکرد کلی" },
        { key: "generalClinic", label: "هزینه حفاظتی" },
        { key: "generalTotal", label: "درآمد کل" },
      ],
      generalExcelShiftsFields: [
        { key: "day", label: "روز" },
        { key: "date", label: "تاریخ" },
        { key: "shiftName", label: "نوبت شیفت" },
        { key: "visitTotalCount", label: "تعداد ویزیت" },
        { key: "visitTotalTotal", label: "کارکرد ویزیت" },
        { key: "baliniTotalCount", label: "تعداد خدمات پرستاری" },
        { key: "baliniTotalTotal", label: "کارکرد خدمات پرستاری" },
        { key: "surgeryTotalCount", label: "تعداد خدمات پزشک" },
        { key: "surgeryTotalTotal", label: "کارکرد خدمات پزشک" },
        // { key: "homeCareTotalCount", label: "تعداد خدمات در منزل" },
        // { key: "homeCareTotalCost", label: "کارکرد خدمات در منزل" },
        { key: "generalCosts", label: "کارکرد کلی" },
        { key: "generalClinic", label: "هزینه حفاظتی" },
        { key: "generalTotal", label: "درآمد کل" },
      ],
      expertiseShiftsFields: [
        { key: "shiftName", label: "نوبت شیفت" },
        { key: "expertClinicalTotalCount", label: "تعداد خدمات تخصصی" },
        { key: "expertClinicalTotalTotal", label: "کارکرد خدمات تخصصی" },
        { key: "cardiovascularCount", label: "تعداد قلب" },
        { key: "cardiovascularTotal", label: "کارکرد قلب" },
        { key: "entCount", label: "تعداد ENT" },
        { key: "entTotal", label: "کارکرد ENT" },
        { key: "gynaecologyCount", label: "تعداد زنان" },
        { key: "gynaecologyTotal", label: "کارکرد زنان" },
        { key: "internalCount", label: "تعداد داخلی" },
        { key: "internalTotal", label: "کارکرد داخلی" },
        { key: "pediatricsCount", label: "تعداد اطفال" },
        { key: "pediatricsTotal", label: "کارکرد اطفال" },
        { key: "counselTotalCost", label: "کارکرد مشاوره" },
        { key: "takhasTotalTotal", label: "کارکرد کلی" },
        { key: "takhasTotalClinic", label: "هزینه حفاظتی" },
        { key: "takhasosTotal", label: "درآمد کل" },
      ],
      expertiseExcelShiftsFields: [
        { key: "day", label: "روز" },
        { key: "date", label: "تاریخ" },
        { key: "shiftName", label: "نوبت شیفت" },
        { key: "expertClinicalTotalCount", label: "تعداد خدمات تخصصی" },
        { key: "expertClinicalTotalTotal", label: "کارکرد خدمات تخصصی" },
        { key: "cardiovascularCount", label: "تعداد قلب" },
        { key: "cardiovascularTotal", label: "کارکرد قلب" },
        { key: "entCount", label: "تعداد ENT" },
        { key: "entTotal", label: "کارکرد ENT" },
        { key: "gynaecologyCount", label: "تعداد زنان" },
        { key: "gynaecologyTotal", label: "کارکرد زنان" },
        { key: "internalCount", label: "تعداد داخلی" },
        { key: "internalTotal", label: "کارکرد داخلی" },
        { key: "pediatricsCount", label: "تعداد اطفال" },
        { key: "pediatricsTotal", label: "کارکرد اطفال" },
        { key: "counselTotalCost", label: "کارکرد مشاوره" },
        { key: "takhasTotalTotal", label: "کارکرد کلی" },
        { key: "takhasTotalClinic", label: "هزینه حفاظتی" },
        { key: "takhasosTotal", label: "درآمد کل" },
      ],
      outserviceShiftsFields: [
        { key: "shiftName", label: "نوبت شیفت" },
        { key: "homeDoctorCount", label: "تعداد ویزیت پزشک" },
        { key: "homeDoctorCost", label: "کارکرد ویزیت پزشک" },
        { key: "homeNurseCount", label: "تعداد ویزیت پرستار" },
        { key: "homeNurseCost", label: "کارکرد ویزیت پرستار" },
        { key: "homePhysioCount", label: "تعداد فیزیوتراپی" },
        { key: "homePhysioCost", label: "کارکرد فیزیوتراپی" },
        { key: "homeDrugCount", label: "تعداد تحویل دارو" },
        { key: "homeDrugCost", label: "کارکرد تحویل دارو" },
        { key: "homeCareTotalCost", label: "درآمد کل" },
      ],
      outserviceExcelFields: [
        { key: "day", label: "روز" },
        { key: "date", label: "تاریخ" },
        { key: "shiftName", label: "نوبت شیفت" },
        { key: "homeDoctorCount", label: "تعداد ویزیت پزشک" },
        { key: "homeDoctorCost", label: "کارکرد ویزیت پزشک" },
        { key: "homeNurseCount", label: "تعداد ویزیت پرستار" },
        { key: "homeNurseCost", label: "کارکرد ویزیت پرستار" },
        { key: "homePhysioCount", label: "تعداد فیزیوتراپی" },
        { key: "homePhysioCost", label: "کارکرد فیزیوتراپی" },
        { key: "homeDrugCount", label: "تعداد تحویل دارو" },
        { key: "homeDrugCost", label: "کارکرد تحویل دارو" },
        { key: "homeCareTotalCost", label: "درآمد کل" },
      ],
      dentistShiftsFields: [
        { key: "shiftName", label: "نوبت شیفت" },
        { key: "visitNo", label: "تعداد دندانپزشکی" },
        { key: "visitCost", label: "کارکرد دندانپزشکی" },
        { key: "visitCost", label: "درآمد کل" },
      ],
      dentistExcelFields: [
        { key: "day", label: "روز" },
        { key: "date", label: "تاریخ" },
        { key: "shiftName", label: "نوبت شیفت" },
        { key: "visitNo", label: "تعداد دندانپزشکی" },
        { key: "visitCost", label: "کارکرد دندانپزشکی" },
        { key: "visitCost", label: "درآمد کل" },
      ],
      labShiftsFields: [
        { key: "shiftName", label: "نوبت شیفت" },
        { key: "labsTotalCount", label: "تعداد آزمایشگاه" },
        { key: "labsTotalCost", label: "کارکرد آزمایشگاه" },
        { key: "labsTotalTotal", label: "درآمد کل" },
      ],
      labExcelFields: [
        { field: "day", label: "روز" },
        { field: "date", label: "تاریخ" },
        { field: "shiftName", label: "نوبت شیفت" },
        { field: "labsTotalCount", label: "تعداد آزمایشگاه" },
        { field: "labsTotalCost", label: "کارکرد آزمایشگاه" },
        { field: "labsTotalTotal", label: "درآمد کل" },
      ],
      commodityShiftsFields: [
        { key: "shiftName", label: "نوبت شیفت" },
        { key: "commodityTotalCount", label: "تعداد اقلام مصرفی" },
        { key: "commodityTotalCosts", label: "کارکرد اقلام مصرفی" },
        { key: "commodityTotalTotal", label: "درآمد کل" },
      ],
      commodityExcelFields: [
        { field: "day", label: "روز" },
        { field: "date", label: "تاریخ" },
        { field: "shiftName", label: "نوبت شیفت" },
        { field: "commodityTotalCount", label: "تعداد اقلام مصرفی" },
        { field: "commodityTotalCosts", label: "کارکرد اقلام مصرفی" },
        { field: "commodityTotalTotal", label: "درآمد کل" },
      ],
      sumFields: [
        { key: "shiftName", label: "نوبت شیفت" },
        { key: "generalCosts", label: "کارکرد کلی عمومی" },
        { key: "generalClinic", label: "هزینه حفاظتی عمومی" },
        { key: "generalTotal", label: "درآمد عمومی" },
        { key: "takhasosCosts", label: "کارکرد کلی تخصصی" },
        { key: "takhasTotalClinic", label: "هزینه حفاظتی تخصصی" },
        { key: "takhasosTotal", label: "درآمد تخصصی" },
        { key: "homeCareTotalCost", label: "درآمد خدمات در منزل" },
        { key: "visitCostDentistry", label: "کارکرد دندانپزشکی" },
        { key: "visitCostDentistry", label: "درآمد دندانپزشکی" },
        { key: "labsTotalTotal", label: "درآمد آزمایشگاه" },
        { key: "commodityTotalTotal", label: "درآمد اقلام مصرفی" },
        { key: "sumAllCosts", label: "کارکرد کل" },
        { key: "sumAllClinic", label: "هزینه حفاظتی کل" },
        { key: "sumAllTotal", label: "درآمد کل" },
      ],
      sumExcelFields: [
        { field: "day", label: "روز" },
        { field: "date", label: "تاریخ" },
        { field: "shiftName", label: "نوبت شیفت" },
        { field: "generalCosts", label: "کارکرد کلی عمومی" },
        { field: "generalClinic", label: "هزینه حفاظتی عمومی" },
        { field: "generalTotal", label: "درآمد عمومی" },
        { field: "takhasosCosts", label: "کارکرد کلی تخصصی" },
        { field: "takhasTotalClinic", label: "هزینه حفاظتی تخصصی" },
        { field: "takhasosTotal", label: "درآمد تخصصی" },
        { field: "homeCareTotalCost", label: "درآمد خدمات در منزل" },
        { field: "visitCostDentistry", label: "کارکرد دندانپزشکی" },
        { field: "visitCostDentistry", label: "درآمد دندانپزشکی" },
        { field: "labsTotalTotal", label: "درآمد آزمایشگاه" },
        { field: "commodityTotalTotal", label: "درآمد اقلام مصرفی" },
        { field: "sumAllCosts", label: "کارکرد کل" },
        { field: "sumAllClinic", label: "هزینه حفاظتی کل" },
        { field: "sumAllTotal", label: "درآمد کل" },
      ],
      sumTotalFields: [
        { key: "sumAllCosts", label: "کارکرد کلی" },
        { key: "sumAllClinic", label: "هزینه حفاظتی" },
        { key: "sumAllTotal", label: "درآمد کل" },
      ],
      daysGeneralTotal: [],
      daysExpertiseTotal: [],
      daysOutServiceTotal: [],
      daysdentistTotal: [],
      daysLabTotal: [],
      daysCommodityTotal: [],
      sum: [],
      // docly fields
      excelGeneralDocFields: [
        { key: "name", label: "نام و نام خانوادگی" },
        { key: "expertise", label: "تخصص" },
        { key: "hours", label: "ساعات کارکرد" },
        { key: "visitCount", label: "تعداد ویزیت" },
        { key: "totalVisitIncome", label: "کارکرد ویزیت (ریال)" },
        { key: "totalBaliniIncome", label: "کارکرد خدمات پرستاری (ریال)" },
        { key: "baliniCount", label: "تعداد خدمات پرستاری" },
        { key: "totalSurgeryIncome", label: "کارکرد خدمات پزشک (ریال)" },
        { key: "surgeryCount", label: "تعداد خدمات پزشکی" },
        { key: "totalTotalIncome", label: "جمع کل کارکرد" },
        { key: "totalVisitShareIncome", label: "سهم پزشک از ویزیت" },
        { key: "totalBaliniShareIncome", label: "سهم پزشک از خدمات پرستاری" },
        { key: "totalSurgeryShareIncome", label: "سهم پزشک از خدمات پزشک" },
        { key: "totalTotalShare", label: "دریافتی از کل کارکرد " },
        { key: "tax", label: "کسورات 10% مالیات" },
        { key: "total", label: "جمع خالص واریزی " },
      ],
      excelDocFields: [
        { key: "name", label: "نام و نام خانوادگی" },
        { key: "expertise", label: "تخصص" },
        { key: "days", label: "تعداد روزها" },
        { key: "expertCount", label: "تعداد ویزیت" },
        { key: "expertClinicalCount", label: "تعداد خدمات" },
        { key: "totalExpertVisitIncome", label: "کارکرد ویزیت تخصصی (ریال)" },
        { key: "totalSurgeryIncome", label: "کارکرد خدمات پزشک (ریال)" },
        { key: "totalTotalIncome", label: "جمع کل کارکرد" },
        {
          key: "totalExpertVisitShareIncome",
          label: "سهم پزشک از ویزیت تخصصی",
        },
        { key: "totalSurgeryShareIncome", label: "سهم پزشک از خدمات پزشک" },
        { key: "totalTotalShare", label: "دریافتی از کل کارکرد " },
        { key: "tax", label: "کسورات 10% مالیات" },
        { key: "total", label: "جمع خالص واریزی " },
      ],
      doclyNonGeneralFields: [
        { key: "name", label: "نام و نام خانوادگی" },
        { key: "expertise", label: "تخصص" },
        { key: "days", label: "تعداد روزها" },
        { key: "expertCount", label: "تعداد ویزیت" },
        { key: "totalExpertVisitIncome", label: "کارکرد ویزیت تخصصی (ریال)" },
        {
          key: "totalExpertClinicalIncome",
          label: "کارکرد خدمات تخصصی (ریال)",
        },
        { key: "totalTotalIncome", label: "جمع کل کارکرد" },
        {
          key: "totalExpertVisitShareIncome",
          label: "سهم پزشک از ویزیت تخصصی",
        },
        {
          key: "totalExpertClinicalShareIncome",
          label: "سهم پزشک از خدمات تخصصی",
        },
        { key: "totalTotalShare", label: "دریافتی از کل کارکرد " },
        { key: "tax", label: "کسورات 10% مالیات" },
        { key: "total", label: "جمع خالص واریزی " },
      ],
      commodityListFields: [
        { key: "name", label: "عنوان" },
        { key: "count", label: "تعداد" },
        { key: "totalCost", label: "جمع مبلغ" },
      ],
      doclyGeneralFields: [
        { key: "name", label: "نام و نام خانوادگی" },
        { key: "hours", label: "ساعات کارکرد" },
        { key: "visitCount", label: "تعداد ویزیت" },
        { key: "totalVisitIncome", label: "کارکرد ویزیت (ریال)" },
        { key: "totalBaliniIncome", label: "کارکرد خدمات پرستاری (ریال)" },
        { key: "totalSurgeryIncome", label: "کارکرد خدمات پزشک (ریال)" },
        { key: "totalTotalIncome", label: "جمع کل کارکرد" },
        { key: "totalVisitShareIncome", label: "سهم پزشک از ویزیت" },
        { key: "totalBaliniShareIncome", label: "سهم پزشک از خدمات پرستاری" },
        { key: "totalSurgeryShareIncome", label: "سهم پزشک از خدمات پزشک" },
        { key: "totalTotalShare", label: "دریافتی از کل کارکرد " },
        { key: "tax", label: "کسورات 10% مالیات" },
        { key: "total", label: "جمع خالص واریزی " },
      ],
      dentistsFields: [
        { key: "name", label: "نام و نام خانوادگی" },
        { key: "days", label: "تعداد روزها" },
        { key: "totalDentistryIncome", label: "کارکرد(ریال)" },
        { key: "totalTotalIncome", label: "جمع کل کارکرد" },
        {
          key: "totalDentistShare",
          label: "سهم پزشک از کارکرد",
        },
        { key: "tax", label: "کسورات 10% مالیات" },
        { key: "total", label: "جمع خالص واریزی " },
      ],
      DocReport: [],
      excelDocReport: [],
      generalDocs: [],
      expertDocs: [],
      dentists: [],
      dailyReport: [],
      commodityList: [],
      excelDailyReport: [],
      dailyReady: false,
      doclyReady: false,
      dateFrom: moment(new Date()).format("jYYYY/jMM/jDD"),
      dateTo: moment(new Date()).format("jYYYY/jMM/jDD"),
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      myExcelItems: [],
      generalExcel: [],
      expertiseExcel: [],
      homecareExcel: [],
      dentistaryExcel: [],
      labExcel: [],
      commodityExcel: [],
      sumExcel: [],
      excelDailyDentristryReport: [],
      dailyDentristry: [],
      visitNoDentistry: 0,
      visitCostDentistry: 0,
    };
  },

  methods: {
    //print() {
    //  window.print();
    //},
    print() {
      //printing Report
      var myWindow = window.open("#", "Report", "height=auto,width=800");
      myWindow.document.write(
        "<html><head><link rel='stylesheet' href='/receipt.css'><title>Report</title>"
      );
      myWindow.document.write("</head><body>");
      myWindow.document.write(document.getElementById("Report").innerHTML);
      myWindow.document.write("</body></html>");
      myWindow.document.close();

      myWindow.onload = function () {
        myWindow.focus();
        setTimeout(() => {
          myWindow.print();
          myWindow.close();
        }, 500);
      };
    },
    pdf(div) {
      var myWindow = window.open("#", "doclyTab", "height=auto,width=800");
      myWindow.document.write(
        "<html><head><link rel='stylesheet' href='/receipt.css'><title>Report</title>"
      );
      myWindow.document.write("</head><body>");
      myWindow.document.write(document.getElementById("doclyTab").innerHTML);
      myWindow.document.write("</body></html>");
      myWindow.document.close();

      myWindow.onload = function () {
        myWindow.focus();
        setTimeout(() => {
          myWindow.print();
          myWindow.close();
        }, 500);
      };
      //NOTE making landscape pdf of div
      //const options = {
      //  name: "_blank",
      //  specs: ["fullscreen=yes", "titlebar=no", "scrollbars=no"],
      //  styles: ["/bootstrap.min.css", "/vuetify.min.css", "/landScape.css"],
      //};
      //this.$htmlToPaper(div, options);
    },
    doclyReport() {
      //NOTE set date and get all doc report from server
      this.vLoading = true;
      this.doclyReady = false;
      //   doctorly
      this.$http
        .post(
          this.baseUrl + "/clinic/reports/overallDoctor",
          {
            startDate: this.dateFrom + "00:00",
            endDate: this.dateTo + "23:59",
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.doclyReady = true;
            this.DocReport = res.data;
            this.excelDocReport = this.DocReport.map((x) => {
              return {
                name: x.name,
                days: x.days,
                hours: x.hours,
                seconds: x.seconds,
                visitCount: x.allTotal.visitCount,
                expertCount: x.allTotal.expertCount,
                expertClinicalCount: x.allTotal.expertClinicalCount,
                expertise: x.expertise,
                totalBaliniIncome: x.allTotal.totalBaliniIncome,
                baliniCount: x.allTotal.baliniCount,
                totalSurgeryIncome: x.allTotal.totalSurgeryIncome,
                surgeryCount: x.allTotal.surgeryCount,
                totalExpertClinicalIncome: x.allTotal.totalExpertClinicalIncome,
                totalTotalIncome: x.allTotal.totalTotalIncome,
                totalVisitIncome: x.allTotal.totalVisitIncome,
                totalExpertVisitIncome: x.allTotal.totalExpertVisitIncome,
                totalDentistryIncome: x.allTotal.totalDentistryIncome,
                total: x.payments.total,
                totalSurgeryShareIncome: x.payments.totalSurgeryIncome,
                totalExpertClinicalShareIncome:
                  x.payments.totalExpertClinicalShareIncome,
                totalTotalShare: x.payments.totalTotalShare,
                totalBaliniShareIncome: x.payments.totalBaliniIncome,
                totalVisitShareIncome: x.payments.totalVisitShareIncome,
                totalExpertVisitShareIncome:
                  x.payments.totalExpertVisitShareIncome,
                tax: x.payments.tax,
                totalDentistShare: x.payments.totalDentistryIncome,
              };
            });
            //NOTE general doctors
            this.generalDocs = this.excelDocReport.filter(
              (z) => z.expertise == "عمومی"
            ); //NOTE filter the excelDocReport data and show the doctors with general experties
            if (this.generalDocs.length > 0) {
              let totalGenerals = {}; //NOTE making a variable for total of each table
              this.doclyGeneralFields.forEach((field) => {
                //NOTE for each  docly general column that we want to show table of it
                try {
                  totalGenerals[field.key] = this.generalDocs
                    .map((x) => x[field.key])
                    .reduce((a, b) => a + b, 0)
                    ? this.generalDocs
                        .map((x) => x[field.key])
                        .reduce((a, b) => a + b, 0) //NOTE mapping through each field and summon columns by key value
                    : 0;
                } catch (error) {
                  totalGenerals[field.key] = 0;
                }
              });
              //total balini count
              totalGenerals.baliniCount = this.generalDocs
                .map((x) => x.baliniCount)
                .reduce((a, b) => a + b, 0)
                ? this.generalDocs
                    .map((x) => x.baliniCount)
                    .reduce((a, b) => a + b, 0) //NOTE mapping through each field and summon columns by key value
                : 0;
              // total surgery count
              totalGenerals.surgeryCount = this.generalDocs
                .map((x) => x.surgeryCount)
                .reduce((a, b) => a + b, 0)
                ? this.generalDocs
                    .map((x) => x.surgeryCount)
                    .reduce((a, b) => a + b, 0) //NOTE mapping through each field and summon columns by key value
                : 0;
              totalGenerals.name = "مجموع"; //NOTE when we reduce the fields change the name to total
              totalGenerals._rowVariant = "info";
              totalGenerals.expertise = "عمومی"; //NOTE when we reduce experties field change result to general
              totalGenerals.seconds = this.generalDocs
                .map((x) => x.seconds)
                .reduce((a, b) => a + b, 0); //NOTE reducing the seconds for getting total seconds
              totalGenerals.hours = this.secondsToHms(totalGenerals.seconds); //NOTE seconds to hms is function to change the total seconds to hours and save it as hours
              this.generalDocs.push(totalGenerals); //NOTE push total row of table to the table
            }

            //NOTE  expert Doctors
            this.expertDocs = this.excelDocReport.filter(
              (z) => z.expertise != "عمومی" && z.expertise != "دندانپزشک"
            ); //NOTE filter doctors and show doctors whose expert is not general

            if (this.expertDocs.length > 0) {
              let totalExperts = {};
              this.doclyNonGeneralFields.forEach((field) => {
                //NOTE for each docly nongeneral fields that we want to show a table of it,do
                try {
                  totalExperts[field.key] = this.expertDocs
                    .map((x) => x[field.key])
                    .reduce((a, b) => a + b, 0)
                    ? this.expertDocs
                        .map((x) => x[field.key])
                        .reduce((a, b) => a + b, 0) //NOTE mapping through each field and summon columns by key value
                    : 0;
                } catch (error) {
                  totalExperts[field.key] = 0;
                }
              });
              totalExperts.expertClinicalCount = this.expertDocs
                .map((x) => x.expertClinicalCount)
                .reduce((a, b) => a + b, 0)
                ? this.expertDocs
                    .map((x) => x.expertClinicalCount)
                    .reduce((a, b) => a + b, 0) //NOTE mapping through each field and summon columns by key value
                : 0;
              totalExperts.name = "مجموع"; //NOTE when we reduce the fields change the name to total
              totalExperts._rowVariant = "info";
              totalExperts.expertise = "-";
              this.expertDocs.push(totalExperts); //NOTE push total experts to expertdocs
            }

            //NOTE  dentists
            this.dentists = this.excelDocReport.filter(
              (z) => z.expertise == "دندانپزشک"
            ); //NOTE filter doctors and show doctors whose expert is not general
            if (this.dentists.length > 0) {
              let totalDentists = {};
              this.dentistsFields.forEach((field) => {
                //NOTE for each docly nongeneral fields that we want to show a table of it,do
                try {
                  totalDentists[field.key] = this.dentists
                    .map((x) => x[field.key])
                    .reduce((a, b) => a + b, 0)
                    ? this.dentists
                        .map((x) => x[field.key])
                        .reduce((a, b) => a + b, 0) //NOTE mapping through each field and summon columns by key value
                    : 0;
                } catch (error) {
                  totalDentists[field.key] = 0;
                }
              });
              totalDentists.name = "مجموع"; //NOTE when we reduce the fields change the name to total
              totalDentists._rowVariant = "info";
              totalDentists.expertise = "-";
              this.dentists.push(totalDentists); //NOTE push total experts to expertdocs
              this.vLoading = false;
            }
            this.vLoading = false;
          } else {
            this.vLoading = false;
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.vLoading = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    secondsToHms(seconds) {
      //NOTE function to change seconds to hours
      seconds = Number(seconds);
      var h = Math.floor(seconds / 3600);
      var m = Math.floor((seconds % 3600) / 60);
      var s = Math.floor((seconds % 3600) % 60);

      var hDisplay = h > 0 ? h + ":" : "00:";
      var mDisplay = m > 0 ? m : "00";
      return hDisplay + mDisplay;
    },
    //NOTE daily
    dayReport() {
      //NOTE sending start date and end date to get day report
      this.vLoading = true;
      this.dailyReady = false;

      this.$http
        .post(
          this.baseUrl + "/clinic/reports/allDay",
          {
            startDate: this.dateFrom + "00:00",
            endDate: this.dateTo + "23:59",
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.vLoading = false;
          if (res.status == 200) {
            this.dailyReady = true;
            this.dailyReport = res.data.daily;
            this.dailyDentristry = res.data.dailyDentristry.shifts;
            this.visitNoDentistry = res.data.dailyDentristry.visitNo;
            this.visitCostDentistry = res.data.dailyDentristry.visitCost;
            this.commodityList = res.data.total.commodityList;
            this.excelDailyReport = this.dailyReport.map((x) => {
              //NOTE mapping through daily report and set day date and shofts
              return {
                date: x.date,
                day: this.weekDict[moment(x.date, "jYYYY/jMM/jDD").weekday()],
                shifts: x.shifts,
              };
            });
            this.excelDailyReport.forEach((r, index) => {
              this.opens.push(index); // this define opened expansions and here we want all expansions to be open
              r.shifts.forEach((z) => {
                z.cardiovascularCount =
                  z.expertise.cardiovascular.cardiovascularCount;
                z.cardiovascularTotal =
                  z.expertise.cardiovascular.cardiovascularTotal;
                z.entCount = z.expertise.ent.entCount;
                z.entTotal = z.expertise.ent.entTotal;
                z.gynaecologyCount = z.expertise.gynaecology.gynaecologyCount;
                z.gynaecologyTotal = z.expertise.gynaecology.gynaecologyTotal;
                z.internalCount = z.expertise.internal.internalCount;
                z.internalTotal = z.expertise.internal.internalTotal;
                z.pediatricsCount = z.expertise.pediatrics.pediatricsCount;
                z.pediatricsTotal = z.expertise.pediatrics.pediatricsTotal;
                z.homeDoctorCount = z.homeCare.homeDoctorCount;
                z.homeDoctorCost = z.homeCare.homeDoctorCost;
                z.homeNurseCount = z.homeCare.homeNurseCount;
                z.homeNurseCost = z.homeCare.homeNurseCost;
                z.homePhysioCount = z.homeCare.homePhysioCount;
                z.homePhysioCost = z.homeCare.homePhysioCost;
                z.homeDrugCount = z.homeCare.homeDrugCount;
                z.homeDrugCost = z.homeCare.homeDrugCost;
                z.labsTotalTotal = z.labsTotalCost;
                z.commodityTotalTotal = z.commodityTotalCosts;
                z.commodityCount = z.homeCare.homeDrugCount;
                z.sumAllCosts =
                  z.generalCosts +
                  z.takhasosCosts +
                  z.commodityTotalCosts +
                  z.labsTotalCost +
                  z.homeCareTotalCost;
                z.sumAllClinic = z.generalClinic + z.takhasTotalClinic;
                z.sumAllTotal =
                  z.generalTotal +
                  z.takhasosTotal +
                  z.labsTotalCost +
                  z.commodityTotalCosts +
                  z.homeCareTotalCost;
              });
            });
            this.myExcelItems = [];
            this.excelDailyReport.forEach((day) => {
              //NOTE getting total shift days
              let totalDay = {};
              this.generalShiftsFields
                .concat(this.expertiseShiftsFields)
                .concat(this.outserviceShiftsFields)
                .concat(this.labShiftsFields)
                .concat(this.commodityShiftsFields)
                .concat(this.sumFields)
                .forEach((field) => {
                  try {
                    totalDay[field.key] = day.shifts
                      .map((x) => x[field.key])
                      .reduce((a, b) => a + b, 0) //NOTE summing the days
                      ? day.shifts
                          .map((x) => x[field.key])
                          .reduce((a, b) => a + b, 0)
                      : 0;
                  } catch (error) {
                    totalDay[field.key] = 0;
                  }
                  totalDay.shiftName = "مجموع"; //NOTE change shift name to total
                  totalDay._rowVariant = "info";
                });
              day.shifts.push(totalDay);
              let myShifts = day.shifts;
              myShifts.forEach((shift) => {
                (shift.date = day.date), (shift.day = day.day);
              });
              this.myExcelItems = this.myExcelItems.concat(myShifts);
            });

            // general
            let generalDayTotalTotal = this.myExcelItems.filter(
              (x) => x.shiftName == "مجموع" //NOTE filter my excel and show shiftname that equals to total
            );

            let generalDaysTotalTemp = {};

            this.generalShiftsFields.forEach((field) => {
              try {
                generalDaysTotalTemp[field.key] = generalDayTotalTotal
                  .map((x) => x[field.key])
                  .reduce((a, b) => a + b, 0) //NOTE summing total days of general doctors
                  ? generalDayTotalTotal
                      .map((x) => x[field.key])
                      .reduce((a, b) => a + b, 0)
                  : 0;
              } catch (error) {
                generalDaysTotalTemp[field.key] = 0;
              }
            });

            this.daysGeneralTotal = [];
            this.daysGeneralTotal.push(generalDaysTotalTemp); //NOTE pushing general days total temp to days general total
            // now we want to add sumTotal to excels
            this.generalExcel = [];
            generalDaysTotalTemp.date = "-";
            generalDaysTotalTemp.shiftName = "-";
            generalDaysTotalTemp.day = "مجموع کل";
            this.generalExcel = this.myExcelItems.concat(generalDaysTotalTemp);
            this.generalExcel.forEach((item, index) => {
              item.shiftName == "مجموع"
                ? this.generalExcel.splice(index, 1)
                : "";
            });
            //NOTE expertise
            let expertiseDayTotalTotal = this.myExcelItems.filter(
              (x) => x.shiftName == "مجموع" //NOTE filter my excel and show shiftname that equals to total
            );

            let expertiseDaysTotalTemp = {};

            this.expertiseShiftsFields.forEach((field) => {
              try {
                expertiseDaysTotalTemp[field.key] = expertiseDayTotalTotal
                  .map((x) => x[field.key])
                  .reduce((a, b) => a + b, 0) //NOTE summing total days of expert doctors
                  ? expertiseDayTotalTotal
                      .map((x) => x[field.key])
                      .reduce((a, b) => a + b, 0)
                  : 0;
              } catch (error) {
                expertiseDaysTotalTemp[field.key] = 0;
              }
            });

            this.daysExpertiseTotal = [];
            this.daysExpertiseTotal.push(expertiseDaysTotalTemp);
            // now we want to add sumTotal to excels
            this.expertiseExcel = [];
            expertiseDaysTotalTemp.date = "-";
            expertiseDaysTotalTemp.shiftName = "-";
            expertiseDaysTotalTemp.day = "مجموع کل";
            this.expertiseExcel = this.myExcelItems.concat(
              expertiseDaysTotalTemp
            );
            this.expertiseExcel.forEach((item, index) => {
              item.shiftName == "مجموع"
                ? this.expertiseExcel.splice(index, 1)
                : "";
            });
            //NOTE outservice
            let outserviceDayTotalTotal = this.myExcelItems.filter(
              (x) => x.shiftName == "مجموع" //NOTE filter my excel and show shiftname that equals to total
            );

            let outserviceDaysTotalTemp = {};

            this.outserviceShiftsFields.forEach((field) => {
              try {
                outserviceDaysTotalTemp[field.key] = outserviceDayTotalTotal
                  .map((x) => x[field.key])
                  .reduce((a, b) => a + b, 0) //NOTE summing total days of expert doctors
                  ? outserviceDayTotalTotal
                      .map((x) => x[field.key])
                      .reduce((a, b) => a + b, 0)
                  : 0;
              } catch (error) {
                outserviceDaysTotalTemp[field.key] = 0;
              }
            });

            this.daysOutServiceTotal = [];
            this.daysOutServiceTotal.push(outserviceDaysTotalTemp);
            // now we want to add sumTotal to excels
            this.homecareExcel = [];
            outserviceDaysTotalTemp.date = "-";
            outserviceDaysTotalTemp.shiftName = "-";
            outserviceDaysTotalTemp.day = "مجموع کل";
            this.homecareExcel = this.myExcelItems.concat(
              outserviceDaysTotalTemp
            );
            this.homecareExcel.forEach((item, index) => {
              item.shiftName == "مجموع"
                ? this.homecareExcel.splice(index, 1)
                : "";
            });
            //NOTE dentistry
            this.excelDailyDentristryReport = this.dailyDentristry.map((x) => {
              //NOTE mapping through daily report and set day date and shofts
              return {
                date: x.date,
                day: this.weekDict[moment(x.date, "jYYYY/jMM/jDD").weekday()],
                shifts: x.shifts.map((y) => {
                  return {
                    shiftName: y.shiftName ? y.shiftName : "-",
                    visitNo: y.visitNo ? y.visitNo : "0",
                    visitCost: y.visitCost ? y.visitCost : "0",
                  };
                }),
              };
            });

            let dentistsTotalTemp = {};
            this.daysdentistTotal = [
              {
                visitNo: this.visitNoDentistry,
                visitCost: this.visitCostDentistry,
              },
            ];
            // now we want to add sumTotal to excels
            this.dailyDentristry.forEach((element, index) => {
              let elementShifts = element.shifts;
              elementShifts.forEach((shift, indexShift) => {
                let newData = {
                  date: element.date,
                  day: this.weekDict[
                    moment(element.date, "jYYYY/jMM/jDD").weekday()
                  ],
                  shiftName: shift.shiftName,
                  visitNo: shift.visitNo,
                  visitCost: shift.visitCost,
                };
                this.dentistaryExcel[Number(index) + Number(indexShift)] =
                  newData;
              });
            });

            dentistsTotalTemp.date = "-";
            dentistsTotalTemp.shiftName = "-";
            dentistsTotalTemp.day = "مجموع کل";
            dentistsTotalTemp.visitNo = this.visitNoDentistry;
            dentistsTotalTemp.visitCost = this.visitCostDentistry;
            this.dentistaryExcel[Number(this.dentistaryExcel.length)] =
              dentistsTotalTemp;

            //NOTE labratory
            let labTotalTotal = this.myExcelItems.filter(
              (x) => x.shiftName == "مجموع" //NOTE filter my excel and show shiftname that equals to total
            );

            let labTotalTemp = {};

            this.labShiftsFields.forEach((field) => {
              try {
                labTotalTemp[field.key] = labTotalTotal.reduce(
                  (a, b) => a + b,
                  0
                ) //NOTE summing total days
                  ? labTotalTotal
                      .map((x) => x[field.key])
                      .reduce((a, b) => a + b, 0)
                  : 0;
              } catch (error) {
                labTotalTemp[field.key] = 0;
              }
            });

            this.daysLabTotal = [];
            this.daysLabTotal.push(labTotalTemp);

            //Note Commodity
            let commodityTotalTemp = {};
            let commodityTotalTotal = this.myExcelItems.filter(
              (x) => x.shiftName == "مجموع" //NOTE filter my excel and show shiftname that equals to total
            );

            this.commodityShiftsFields.forEach((field) => {
              try {
                commodityTotalTemp[field.key] = commodityTotalTotal.reduce(
                  (a, b) => a + b,
                  0
                ) //NOTE summing total days
                  ? commodityTotalTotal
                      .map((x) => x[field.key])
                      .reduce((a, b) => a + b, 0)
                  : 0;
              } catch (error) {
                commodityTotalTemp[field.key] = 0;
              }
            });
            this.daysCommodityTotal = [];
            this.daysCommodityTotal.push(commodityTotalTemp);

            // now we want to add sumTotal to excels
            labTotalTemp.date = "-";
            labTotalTemp.shiftName = "-";
            labTotalTemp.day = "مجموع کل";
            this.labExcel = this.myExcelItems.concat(labTotalTemp);
            this.labExcel.forEach((item, index) => {
              item.shiftName == "مجموع" ? this.labExcel.splice(index, 1) : "";
            });
            commodityTotalTemp.date = "-";
            commodityTotalTemp.shiftName = "-";
            commodityTotalTemp.day = "مجموع کل";
            this.commodityExcel = this.myExcelItems.concat(commodityTotalTemp);
            this.commodityExcel.forEach((item, index) => {
              item.shiftName == "مجموع"
                ? this.commodityExcel.splice(index, 1)
                : "";
            });
            //NOTE sum
            let sum = this.myExcelItems.filter((x) => x.shiftName == "مجموع"); //NOTE adding row of total for shifts
            let sumTemp = {};

            this.sumFields.forEach((field) => {
              try {
                if (field.key == "sumAllTotal" || field.key =="sumAllCosts") {
                  sumTemp[field.key] = sum
                    .map((x) => x[field.key])
                    .reduce((a, b) => a + b, 0)
                    ? sum.map((x) => x[field.key]).reduce((a, b) => a + b, 0) +
                      (this.visitCostDentistry
                        ? Number(this.visitCostDentistry)
                        : 0) //NOTE get sum of tabl's field at end
                    : 0 +
                      (this.visitCostDentistry
                        ? Number(this.visitCostDentistry)
                        : 0);
                } else {
                  sumTemp[field.key] = sum
                    .map((x) => x[field.key])
                    .reduce((a, b) => a + b, 0)
                    ? sum.map((x) => x[field.key]).reduce((a, b) => a + b, 0) //NOTE get sum of tabl's field at end
                    : 0;
                }
              } catch (error) {
                sumTemp[field.key] = 0;
              }
            });
            this.sum = [];
            this.sum.push(sumTemp);
            // now we want to add sumTotal to excels
            this.sumExcel = [];
            sumTemp.date = "-";
            sumTemp.shiftName = "-";
            sumTemp.day = "مجموع کل";
            this.sumExcel = this.myExcelItems.concat(sumTemp);
            this.sumExcel.forEach((item, index) => {
              item.shiftName == "مجموع" ? this.sumExcel.splice(index, 1) : "";
            });
            this.sumExcel[Number(this.sumExcel.length) - 1].visitCostDentistry =
              this.visitCostDentistry;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.vLoading = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    sideBarMinimize() {
      document.body.classList.toggle("sidebar-minimized");
      document.body.classList.toggle("brand-minimized");
    },
  },
  computed: {},
  mounted() {
    if (window.screen.width < 950) {
      this.deviceType = "mobile";
    } else {
      if (document.body.clientWidth < 950) {
        this.deviceType = "mobile";
      } else {
        this.deviceType = "desktop";
      }
    }
    this.sideBarMinimize();
  },
};
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
  .v-tabs--vertical {
    display: flex !important;
    flex-direction: column !important;
  }
  .v-tab {
    border-bottom: 1px solid #ccc;
  }
  .v-window {
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 600px) {
  .v-window {
    border-top-left-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
    margin-bottom: 10px;
  }
}
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
table {
  break-inside: avoid;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
}
.break-avoid {
  break-inside: avoid;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
}

@media print {
  .noPrint {
    visibility: hidden !important;
  }

  table {
    break-inside: avoid;
    page-break-inside: avoid;
    -webkit-column-break-inside: avoid;
  }
  .break-avoid {
    break-inside: avoid;
    page-break-inside: avoid;
    -webkit-column-break-inside: avoid;
  }
  td,
  th {
    font-size: 80%;
  }
}
</style>
